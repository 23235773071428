// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from "jquery";
import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'jquery.scrollto';

import '../components/jquerytools/overlay/overlay'
import '../components/jquerytools/overlay/overlay.apple'
import '../components/jquerytools/scrollable/scrollable'
import '../components/jquerytools/scrollable/scrollable.autoscroll'

import "../components/jquery.questioner"
import "../components/infobubble"
import "../components/jquery.autoresizetext"
import "../components/jquery.selectbox"

import "../components/frontend"
import "../components/jquery.worldmap"

// https://stackoverflow.com/questions/37738732/jquery-3-0-url-indexof-error
// jquery 3.x call load function different. This is fallback for legacy code to working
jQuery.fn.load = function(callback){ $(window).on("load", callback) };

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

window.jQuery = jQuery;
window.$ = $;
