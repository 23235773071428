/*
 *  Application logic, to split jQuery DOM ready into several separate parts.
 */

function Application() {

}

// Forms guiding function.
Application.prototype.construct = function(sel) {
    var t = $(sel), method = '_' + sel.replace(/[#.]/, '');

    if (!t.length) {
        return false;
    }

    if (this[method] !== undefined) {
        this[method](t);
    }
};

// Submit button for the form with its change highlights.
Application.prototype._submitFormControls = function(context) {
    context.find('.jButtonSubmit').on('click', function() {
        context.trigger('submit');
        return false;
    });
};

Application.prototype._jMapRecords = function(context) { 

    var geoloc = window.geolocations,
        cssClass = window.geolocationsClass,
        markerBounds = new google.maps.LatLngBounds(),
        infoBubbles = [],
        markers = [],
        spriteOffset = { firm: 0, exotic: 19, golf: 38, incentiva: 57 },
        map = new google.maps.Map(context.get(0), {
            zoom: 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });

    for (var i = 0, l = geoloc.length; i < l; i++) {
        var latLng = new google.maps.LatLng(geoloc[i].lat, geoloc[i].lng),
            marker = new google.maps.Marker({
                position: latLng,
                map: map,
                icon: new google.maps.MarkerImage(window.asset_sprites_pin,
                    new google.maps.Size(19, 33),
                    new google.maps.Point(spriteOffset[cssClass], 0),
                    new google.maps.Point(9, 33)),
                shadow: new google.maps.MarkerImage(window.asset_shadow_pin,
                    new google.maps.Size(35, 15),
                    new google.maps.Point(0, 0),
                    new google.maps.Point(0, 16))
            }),
            infoBubble = new InfoBubble({
                map: map,
                content: '<div class="gm-info-bubble ' + cssClass + '"><span></span><span>' + geoloc[i].title + '</span><span></span></div>',
                shadowStyle: 3,
                padding: 0,
                backgroundColor: 'transparent',
                arrowStyle: 3,
                borderWidth: 0,
                disableAutoPan: true,
                hideCloseButton: true
            });
            
        (function(m, ib) {
            google.maps.event.addListener(m, "click", function(e) {
                for (var i = infoBubbles.length - 1; i >= 0; i--) {
                    infoBubbles[i].close();
                }
                ib.open(map, m);              
            });
        })(marker, infoBubble);

        infoBubbles.push(infoBubble);
        markers.push(marker);
        markerBounds.extend(latLng);
    }

    // Zoom to fit all markers on the map.
    if (geoloc.length > 0) {
        map.fitBounds(markerBounds);
        
        // Open first marker.
        var openMarker = google.maps.event.addListener(map, "bounds_changed", function() {
            infoBubbles[0].open(map, markers[0]);
            google.maps.event.removeListener(openMarker);
        });
        
        // FitBounds is asynchronouse, create self removing listener to set zoom limit.
        if (geoloc.length == 1) {
            var zoomLimit = google.maps.event.addListener(map, "bounds_changed", function() {
                if (map.zoom > 12) {
                    map.setZoom(12);
                }
                google.maps.event.removeListener(zoomLimit);
            });
        }
    }
    
};
    
// Map for the contact.
Application.prototype._jMapContact = function(context) {

    var lat = context.data('latitude'),
        lng = context.data('longitude'),
        latLng = new google.maps.LatLng(lat, lng),
    
        map = new google.maps.Map(context.get(0), {
            zoom: 16,
            disableDefaultUI: true,
            panControl: false,
            zoomControl: true,
            scaleControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latLng
        }),
        
        marker = new google.maps.Marker({
            position: latLng,
            map: map,
            icon: new google.maps.MarkerImage(window.asset_sprites_pin,
                new google.maps.Size(19, 33),
                new google.maps.Point(38, 0),
                new google.maps.Point(9, 33)),
            shadow: new google.maps.MarkerImage(window.asset_shadow_pin,
                new google.maps.Size(35, 15),
                new google.maps.Point(0, 0),
                new google.maps.Point(0, 16))
        });
        
};

// Form for questioning.
Application.prototype._jFormQuestion = function(context) {
    $('.jButtonQuestionSimple').questioner(context);
    $('.jButtonQuestionDetailed').questioner(context);

    if (location.hash == '#kontakt') {
        $('.jButtonQuestionSimple').trigger('click');
    }
    
};

// Form for destinatin filter in hotel resutls.
Application.prototype._jFormDestinationFilter = function(context) {

    context.find('.select').selectbox({
        change: function() {
            context.trigger('submit');
        }
    });
    
};

// Form for the newsletter.
Application.prototype._jFormNewsletter = function(context) {

    this._submitFormControls(context);
    
};

// Form for the contact.
Application.prototype._jFormContact = function(context) {

    this._submitFormControls(context);
    
};

// Form for the map.
Application.prototype._jFormMapSelects = function(context) {

    this._submitFormControls(context);

    var destinations = context.find('.jSelectBoxDestinations select').clone(),
        hotels = context.find('.jSelectBoxHotels select').clone(),
        regId = 0,
        regName = '',
        countId = 0,
        countName = '';
    
    function changeFn(item) {
        var t = $(item);
        regId = t.data('value');
        regName = t.text();
        
        filterIt('region');
    }
    
    function filterIt(parent) {
        var selH = hotels.clone(),
            selD = destinations.clone();

        if (countId != 0 && parent == 'country') {
            // Filter destinations.
            selD.find('option').each(function() {
                var t = $(this);
                
                if (t.data('country') != countId) {
                    t.remove();
                }
            });
            
            selD.prepend('<option value="">Zvolte destinaci (' + countName + ')</option>');            
            context.find('.jSelectBoxDestinations').html('').off('click').append(selD).selectbox({ change: changeFn });

            // Filter hotels.            
            selH.find('option').each(function() {
                var t = $(this);
                
                if (t.data('country') != countId) {
                    t.remove();
                }
            });
            
            selH.prepend('<option value="">Zvolte hotel (' + countName + ')</option>');            
            context.find('.jSelectBoxHotels').html('').off('click').append(selH).selectbox();

        } else if (regId != 0 && parent == 'region') {
            // Filter hotels.
            selH.find('option').each(function() {
                var t = $(this);
                
                if (t.data('region') != regId) {
                    t.remove();
                }
            });
            
            selH.prepend('<option value="">Zvolte hotel (' + regName + ')</option>');            
            context.find('.jSelectBoxHotels').html('').off('click').append(selH).selectbox();
        }
        console.log(regId);
    }

    context.find('.jSelectBoxStates').selectbox({
        change: function(item) {
            var t = $(item);
            countId = t.data('value');
            countName = t.text();
            
            filterIt('country');
        }
    });
    
    context.find('.jSelectBoxDestinations').selectbox({ change: changeFn });
    context.find('.jSelectBoxHotels').selectbox();
    
};

// World map system.
Application.prototype._jWorldMapGolf = function(context) {

    context.worldmap({ type: 'golf', url: '/map.json?type=golf', state: context.data('state') });

};
    
Application.prototype._jWorldMapExotic = function(context) {

    context.worldmap({ type: 'exotic', url: '/map.json?type=exotic', state: context.data('state') });

};

// Photo gallery in product detail.
Application.prototype._jGallery = function(context) {

    var preview = context.find('.preview'),
        image = undefined,
        spinner = null;
    
    context.find('.browse').on('click', function() {
        return false;
    });
    
    context.find('.items a').on('click', function() {
        // Cancel current loading.
        if (image !== undefined) {
            image.off('load');
        }

        // Load another image.
        image = $('<img />').attr('src', $(this).attr('href')).hide().on('load', function() {
            clearTimeout(spinner);
            preview.html($(this)).removeClass('spinner');
            $(this).stop(true,true).fadeIn(200);
        });
        
        // Clear preview image.
        preview.find('img').stop(true,true).fadeOut(200);
        
        // Attach spinner circle with delay, it's baaad to present spinner for loaded images.
        clearTimeout(spinner);
        spinner = setTimeout(function() {
            preview.addClass('spinner');
        }, 200);
        
        return false;
    }).first().trigger('click');
    
    context.find('.scrollable').scrollable();

};

// Promo cross fade effects.
Application.prototype._jPromo = function(context) {

    var interval = null,
        timer = null,
        crossFadeInterval = 8000,
        crossFadeLoading = undefined,
        transition = $('<div />').addClass('transition'),
        index = 1,
        images = {
            golf: {
                url: window.asset_bg_big_golf,
                loaded: false
            },        
            exotic: {
                url: window.asset_bg_big_exotic,
                loaded: false
            },
            incentiva: {
                url: window.asset_bg_big_incentiva,
                loaded: false
            }    
        };
    
    function crossfade(faster) {
        var key = index === 0 ? 'golf' : (index === 1 ? 'exotic' : 'incentiva'),
            animationInterval = faster === true ? 200 : 1000;

        if (images[key].loaded === true) {
            $('.jPromoLink').removeClass('active');
            $('.' + key + ' .jPromoLink').addClass('active');
            
            transition.css('opacity', 0).removeClass('golf').removeClass('exotic').removeClass('incentiva').addClass(key);
            crossFadeLoading = key;
            $('body').append(transition);

            transition.stop().animate({ opacity: 1 }, animationInterval, function() {
                $('body').removeClass('golf-hp').removeClass('exotic-hp').removeClass('incentiva-hp').addClass(key + '-hp');
                transition.remove();
                crossFadeLoading = undefined;
            })

            index = index >= 2 ? 0 : index + 1;
        }
    }
    
    function hoverCrossfade(newIndex) {
        clearTimeout(timer);
        index = newIndex;

        var key = index === 0 ? 'golf' : (index === 1 ? 'exotic' : 'incentiva')

        if (crossFadeLoading !== undefined) {
            timer = setTimeout(function() {
                hoverCrossfade(newIndex);
            }, 20)
        } else {
            crossfade(true);
        }
    }
    
    // Preload all images.
    for (var key in images) {
        (function(image) {
            image.dom = $('<img />').attr('src', image.url).load(function() {
                image.loaded = true;
            });            
        })(images[key]);
    }
    
    // Launch cross fading.
    interval = setInterval(crossfade, crossFadeInterval);
    
    // Quick cross fade on mouse hover.
    $('.jPromoLink').on('mouseenter', function() {
        clearInterval(interval);
        
        var divClass = $(this).closest('div').attr('class'),
            newIndex = divClass == 'golf' ? 0 : (divClass == 'exotic' ? 1 : 2),
            newClass = newIndex === 0 ? 'golf' : (newIndex === 1 ? 'exotic' : 'incentiva');
            
        if (crossFadeLoading != newClass) {
            hoverCrossfade(newIndex);
        }
    }).on('mouseleave', function() {
        clearTimeout(timer);
        interval = setInterval(crossfade, crossFadeInterval);
    });
};


/*
 *  All the jQuery mess to fire on each page load.
 */
$(function() {
    
    var app = new Application();
    
    app.construct('#jFormMapSelects');
    app.construct('#jFormContact');
    app.construct('#jFormNewsletter');
    app.construct('#jFormQuestion');
    app.construct('#jFormDestinationFilter');
    
    app.construct('#jMapContact');
    app.construct('#jMapRecords');
    
    app.construct('#jWorldMapGolf');
    app.construct('#jWorldMapExotic');    
    
    app.construct('#jPromo');
    
    app.construct('#jGallery');
    
    // Autoshrink for country name.
    $('#jCountryName').autoResizeText({ maxHeight: 75, maxWidth: 375 });
    
    // Make print link printable.
    $('#jLinkPrint').on('click', function() {
        if (window.print !== undefined) {
            window.print();
        }
        return false;
    });
    
    // Scroll between boxes in detail pages.
    $('.jNavBar a').on('click', function() {
        var id = $(this).attr('href');
        $('body').stop(true).scrollTo($(id), 500);
        return false; 
    });
    
    
});
