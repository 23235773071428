// $.fn.worldmap - World map for continent and state selection.

(function($) {

    /*
    Data structure of world map json:

    [(continent){
        id: 2,
        pin: { x: 10, y: 22, title: 'Afrika' },
        zoom: { x1: 0, x2: 100, y1: 0, y2: 200 },
        polygons: [
            (polygon){ points: [(point){ x:13, y:1 }, { x:13, y:1 }] },
            (polygon){ points: [(point){ x:13, y:1 }, { x:13, y:1 }] }
        ],
        states: [
            (state){
                id: 4,
                key: 'francouzska-polynesie',
                pin: { x: 10, y: 22, title: 'Francouzska Polynesie' },
                zoom: { x1: 0, x2: 100, y1: 0, y2: 200 },
                polygons: [
                    (polygon){ points: [(point){ x:13, y:1 }, { x:13, y:1 }] },
                    (polygon){ points: [(point){ x:13, y:1 }, { x:13, y:1 }] }
                ]
            }
        ]    
    }]
    */
    
    // Pseudo constants...
    var kMapWidth = 4394,
        kMapHeight = 2384,

        kCanvasWidth = 0,
        kCanvasHeight = 0,
        kCanvasRatio = 0,

        kZoomContinents = 0,
        kZoomStates = 1;
        
    
    /*
     *  Point convert for the map dimensions.
     */
    function Point(x, y, rect) {
        this.x = x;
        this.y = y;
        this.rect = rect;
        this.mapPoint = undefined;
        this.canvasPoint = undefined;
    }
    
    Point.prototype.isInPolygon = function(polygon) {
        var poly = polygon.points,
            pt = {
                x: this.convertForMap().x,
                y: this.convertForMap().y
            };
            
        for(var c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i)
            ((poly[i].y <= pt.y && pt.y < poly[j].y) || (poly[j].y <= pt.y && pt.y < poly[i].y))
            && (pt.x < (poly[j].x - poly[i].x) * (pt.y - poly[i].y) / (poly[j].y - poly[i].y) + poly[i].x)
            && (c = !c);
            
        return c;
    };
    
    Point.prototype.convertForMap = function() {
        if (this.mapPoint === undefined) {
            this.mapPoint = {
                x: this.x / this.rect.xr + this.rect.x1,
                y: this.y / this.rect.yr + this.rect.y1
            }
        }        
        return this.mapPoint;
    };
    
    Point.prototype.convertForCanvas = function() {
        if (this.canvasPoint === undefined) {
            this.canvasPoint = {
                x: (this.x - this.rect.x1) * this.rect.xr,
                y: (this.y - this.rect.y1) * this.rect.yr
            }
        }        
        return this.canvasPoint
    };
    

    /*
     *  Pin marker with bubble for the map.
     */
    function Pin(container) {
        this.pinBubbleText = $('<span />').text('hmm');
        this.pinBubble = bubble = $('<div />').addClass('gm-info-bubble').append('<span></span>').append(this.pinBubbleText).append('<span></span>');
        this.pin = $('<div />').addClass('pin').append('<div class="marker"></div>');
        
        // Append pin to the DOM.
        container.append(this.pin).append(this.pinBubble);
    }
    
    Pin.prototype.show = function(pt, title) {
        this.pinBubbleText.text(title);
        // this.pinBubble.get(0).style.webkitAnimation = 'none';
        this.pinBubble.css('visibility', 'hidden').show();
        
        var self = this,
            offset = this.pinBubble.width() / 2 - 1;
            
        this.pinBubble.hide().removeClass('animation').css({
            visibility: '',
            top: pt.convertForCanvas().y - 85,
            left: pt.convertForCanvas().x - offset
        }).show();
        
        // this.pinBubble.get(0).style.webkitAnimation = '';
        
        this.pin.css({ 
            top: pt.convertForCanvas().y, 
            left: pt.convertForCanvas().x,
        }).show();
    };
    
    Pin.prototype.hide = function() {
        this.pinBubble.hide();
        this.pin.hide();
    };    
    
            
    /*
     *  Map class to keep clean login.
     */
    function Map(ctx, container, url, type, state) {
        this.ctx = ctx;
        this.url = url;
        this.type = type;
        this.w = ctx.canvas.width;
        this.h = ctx.canvas.height;
        this.pin = new Pin(container);
        this.returner = $('<a />').addClass('return').attr('href','#').append('<span>Zobrazit celý svět</span>').hide();
        this.container = container;
        this.canvasBuffer = undefined;
        this.highlightId = 0;
        this.selectedState = state;
        this.selectedContinent = 0;
        this.rect = undefined;
        this.data = [];
        
        var self = this;
        
        // Add returner button to the DOM.
        container.append(this.returner);
        this.returner.on('click', function() {
            self.returner.hide();
            self.selectedContinent = 0;
            self.createRect(490, 4304, 257, 2300);
            self.canvasBuffer = undefined;
            self.clearMap();
            return false;
        });
       
        // Attach click on the pin to make it clickthroughable.
        this.pin.pin.on('click', function(e) {
            var t = $(this),
                offset = t.offset(),
                y = parseInt(t.css('top')) - 33 + (e.pageY - offset.top),
                x = parseInt(t.css('left')) - 10 + (e.pageX - offset.left);

            self.click(x, y);
        })
        
        // Create initial rectangle area for world map to fit all continents.
        this.createRect(490, 4304, 257, 2300);
    }
    
    Map.prototype.load = function() {
        var self = this;
                
        $.ajax({
            url: self.url,
            type: 'GET',
            dataType: 'json',
            success: function(data) {
                self.data = data;

                // Create rect for state presentation.
                if (self.selectedState !== undefined) {
                    self.createRectForState(self.selectedState);
                }

                self.drawMap();
            }
        });
    };

    Map.prototype.createRect = function(x1, x2, y1, y2) {
        var r = { x1: x1, x2: x2, y1: y1, y2: y2 },
            w = r.x2 - r.x1,
            h = r.y2 - r.y1,
            ratio = w / h;

        // Correct rectangle ratio, because it can fit only to specific canvas ratio.
        if (ratio > kCanvasRatio) {
            var h2 = h / kCanvasRatio * h / w,
                yoff = (h2 - h) / 2;
            
            r.y1 -= yoff;
            r.y2 += yoff;
            
        } else if (ratio < kCanvasRatio) {
            var w2 = w * kCanvasRatio * h / w,
                xoff = (w2 - w) / 2;
            
            r.x1 -= xoff;
            r.x2 += xoff;
        }
        
        // Check if selected area is bigger than canvas to avoid scale higher than original.
        if (r.x2 - r.x1 < kCanvasWidth) {
            var xoff = (kCanvasWidth - (r.x2 - r.x1)) / 2,
                yoff = (kCanvasHeight - (r.y2 - r.y1)) / 2;
                
            r.x1 -= xoff;
            r.x2 += xoff;
            r.y1 -= yoff;
            r.y2 += yoff;
        }
        
        // Calculate ratio for points conversions.
        r.xr = kCanvasWidth / (r.x2 - r.x1);
        r.yr = kCanvasHeight / (r.y2 - r.y1);
        
        // Save this canvas rectangle as final.
        this.rect = r;
    };
    
    Map.prototype.createRectForState = function(id) {
        var rect = undefined;

        for (var i = this.data.length - 1; i >= 0; i--) {
            for (var j = 0, lj = this.data[i].states.length; j < lj; j++) {
                var state = this.data[i].states[j];
                
                if (state.id == id) {
                    var polygons = state.polygons;

                    if (state.zoom !== undefined) {
                        rect = state.zoom;
                        break;
                    }
                    rect = { x1: Infinity, x2: 0, y1: Infinity, y2: 0 };                    
                    
                    for (var k = 0, lk = polygons.length; k < lk; k++) {
                        for (var l = 0, ll = polygons[k].points.length; l < ll; l++) {
                            var pt = polygons[k].points[l];

                            if (pt.x < rect.x1) {
                                rect.x1 = pt.x;
                            }
                            if (pt.x > rect.x2) {
                                rect.x2 = pt.x;
                            }
                            if (pt.y < rect.y1) {
                                rect.y1 = pt.y;
                            }
                            if (pt.y > rect.y2) {
                                rect.y2 = pt.y;
                            }                        
                        }
                    }
                    
                    break;
                }
                if (rect !== undefined) { break; }
            }
            if (rect !== undefined) { break; }
        }

        if (rect !== undefined) {
            var w = rect.x2 - rect.x1,
                h = rect.y2 - rect.y1,
                xoff = w * 0.48 / 2,
                yoff = h * 0.48 / 2;
                
            rect.x1 += xoff;
            rect.x2 -= xoff;
            rect.y1 -= yoff;
            rect.y2 += yoff;
            
            this.createRect(rect.x1, rect.x2, rect.y1, rect.y2);
            
            // standard width: 960, presentation width: 445 floated left +20 left padding
            var woff = (this.rect.x2 - this.rect.x1) / 4;

            this.rect.x1 += woff;
            this.rect.x2 += woff;
        }
    };
            
    Map.prototype.drawRegion = function(polygons, highlight, stateType) {
        this.ctx.save();
        
        if (highlight === true) {
            this.ctx.fillStyle = this.type == 'golf' ? 'rgb(99,164,10)' : 'rgb(112,95,165)';
            this.ctx.strokeStyle = this.type == 'golf' ? 'rgb(80,137,8)' : 'rgb(93,76,138)';
        } else if (stateType === true) {
            this.ctx.fillStyle = this.type == 'golf' ? 'rgb(195,209,172)' : 'rgb(196,193,212)';
        }

        for (var i = polygons.length - 1; i >= 0; i--) {
            var pt = new Point(polygons[i].points[0].x, polygons[i].points[0].y, this.rect);
            
            this.ctx.beginPath();
            this.ctx.moveTo(pt.convertForCanvas().x, pt.convertForCanvas().y);

            for (var j = 1, l = polygons[i].points.length; j < l; j++) {
                var pt = new Point(polygons[i].points[j].x, polygons[i].points[j].y, this.rect);
                this.ctx.lineTo(pt.convertForCanvas().x, pt.convertForCanvas().y);
            }

            this.ctx.stroke();
            this.ctx.fill();
        }
        
        this.ctx.restore();
    };
    
    Map.prototype.drawMap = function() {
        if (this.data.length == 0) {
            return;
        }

        // Draw map from buffer if it is available.
        if (this.canvasBuffer !== undefined) {
            this.ctx.drawImage(this.canvasBuffer, 0, 0);
            return;
        }
        
        // Gradient background.
        var gradient = this.ctx.createLinearGradient(0, 0, 0, this.h);
        gradient.addColorStop(0, '#fefefe');
        gradient.addColorStop(1, '#f4f4f4');
        
        this.ctx.fillStyle = gradient;
        this.ctx.fillRect(0, 0, this.w, this.h);
        
        // Common draw attributes.
        this.ctx.fillStyle = 'rgb(221,222,223)';
        this.ctx.strokeStyle = 'rgb(162,164,166)';
        this.ctx.lineWidth = 1;
        
        // Draw world map from scratch.
        for (var i = this.data.length - 1; i >= 0; i--) {
            var polygons = this.data[i].polygons,
                states = this.data[i].states;
            
            this.drawRegion(polygons);

            for (var b = 0, lb = states.length; b < lb; b++) {
                this.drawRegion(states[b].polygons, 
                    (this.selectedState !== undefined && states[b].id == this.selectedState), 
                    ((this.selectedContinent != 0 && this.selectedContinent == this.data[i].id) || this.selectedContinent == 0) ? true : false);
            }
        }
        
        // Buffer hard drawn world map.
        var can = $('<canvas />').get(0),
            ctx = can.getContext('2d');

        can.width = this.w;
        can.height = this.h;
        ctx.drawImage(this.ctx.canvas, 0, 0);
        this.canvasBuffer = can;
    };
    
    Map.prototype.clearMap = function() {
        this.drawMap();
        this.highlightId = 0;
        this.pin.hide();
        $(this.ctx.canvas).removeClass('highlighted');
    };
    
    Map.prototype._clickOnContinent = function(x, y) {
        for (var i = this.data.length - 1; i >= 0; i--) {
            if (this.data[i].id == this.highlightId) {
                var rect = this.data[i].zoom;

                this.returner.show();
                this.selectedContinent = this.data[i].id;
                this.createRect(rect.x1, rect.x2, rect.y1, rect.y2);
                this.canvasBuffer = undefined;
                this.clearMap();
            }
        }
    };
    
    Map.prototype._clickOnState = function(x, y) {
        var state = undefined;
        
        for (var i = this.data.length - 1; i >= 0; i--) {
            var states = this.data[i].states;

            for (var j = 0, lj = states.length; j < lj; j++) {
                if (states[j].id == this.highlightId) {
                    state = states[j];
                    break;
                }
            }
            if (state != undefined) { break; }
        }
        
        if (state !== undefined) {
            location.href = location.href + '/' + state.key
        }
    };
    
    Map.prototype.click = function(x, y) {
        if (this.selectedContinent == 0) {
            this._clickOnContinent(x, y);
        } else {
            this._clickOnState(x, y);
        }
    };
    
    Map.prototype.findContinentForPosition = function(x, y) {
        var pt = new Point(x, y, this.rect),
            continent = undefined;
            
        for (var i = this.data.length - 1; i >= 0; i--) {
            var polygons = this.data[i].polygons;

            for (var j = 0, lj = polygons.length; j < lj; j++) {
                if (pt.isInPolygon(polygons[j]) === true) {
                    continent = this.data[i];
                    break;
                }
            }
            if (continent !== undefined) { break; }
        }
        
        return continent;
    };    
    
    Map.prototype.findStateForPosition = function(x, y) {
        var pt = new Point(x, y, this.rect),
            state = undefined;
            
        for (var i = this.data.length - 1; i >= 0; i--) {
            if (this.data[i].id == this.selectedContinent) {
                var states = this.data[i].states;

                for (var j = 0, lj = states.length; j < lj; j++) {
                    for (var k = 0, lk = states[j].polygons.length; k < lk; k++) {
                        if (pt.isInPolygon(states[j].polygons[k]) === true) {
                            state = states[j];
                            break;
                        }
                    }                
                    if (state != undefined) { break; }
                }
            }
            if (state != undefined) { break; }
        }
        
        return state;
    };
    
    Map.prototype.highlight = function(x, y) {
        if (this.data.length == 0) {
            return;
        }

        // Universal variable for state and continent aswell.
        var region = undefined;
            
        // Find correct type for position, those are heavy workload calculations.
        if (this.selectedContinent == 0) {
            region = this.findContinentForPosition(x, y);
        } else {
            region = this.findStateForPosition(x, y);
        }
        
        if (region !== undefined && region.id != this.highlightId) {
            var pt = new Point(region.pin.x, region.pin.y, this.rect),
                polygons = region.polygons,
                states = region.states !== undefined ? region.states : [];

            this.highlightId = region.id;
            this.drawMap();
            this.drawRegion(polygons, true);
            
            for (var b = 0, lb = states.length; b < lb; b++) {
                this.drawRegion(states[b].polygons, true);
            }

            this.pin.show(pt, region.pin.title);
            $(this.ctx.canvas).addClass('highlighted');
        }
        
        // Clear highlighted continent because cursor is outside of any continent and highlightId is set.
        if (region === undefined && this.highlightId != 0) {
            this.clearMap();
        }        
    };
    
    
    /*
     *  World map - World map for continent and state selection.
     */
    $.fn.worldmap = function(options) {
        if (!this.length) {
            return this;
        }
        
        var container = $(this),
            canvas = $('<canvas />').get(0),
            ctx = canvas.getContext('2d'),
            map = undefined;

        // Set basic dimensions and append canvas to the DOM.
        canvas.width = kCanvasWidth = container.width();
        canvas.height = kCanvasHeight = container.height();
        container.append(canvas);
        kCanvasRatio = kCanvasWidth / kCanvasHeight;
        
        // Initialize map object and render world map.
        map = new Map(ctx, container, options.url, options.type, options.state);
        map.load();
        
        // State is not specified, make map interactive.
        if (options.state === undefined) {
            
            $(canvas).on('mousemove', function(e) {
                var offset = $(this).offset(),
                    x = e.pageX - offset.left,
                    y = e.pageY - offset.top;

                map.highlight(x, y);

            }).on('click', function(e) {
                var offset = $(this).offset(),
                    x = e.pageX - offset.left,
                    y = e.pageY - offset.top;

                map.click(x, y);
            });
            
        }
        
        return this;
    };
    
})(window.jQuery);
