// $.fn.questioner - question form

(function($) {
    
    /*
     *  Question form - it requires specific html DOM!
     */
    $.fn.questioner = function(form) {
        if (!this.length) {
            return this;
        }
        
        var formHtml = form.find('.question-form'),
            link = $(this),
            message = $('#jQuestionMessage'),
            messageTimer = null,

            overlay = $('<div />').addClass('overlay').on('click', function() {
                link.trigger('click');
            }),

            messageCloser = function() {
                message.fadeOut(150, function() {
                    message.remove();
                });
                clearTimeout(messageTimer);
                $('body').off('click', messageCloser);
            },
            
            cancel = function(k) {
                if (k.keyCode == '27') {
                    link.trigger('click');
                }
            };
        
        // Flash message after questioner submition.
        if (message.length > 0) {
            $('body').on('click', messageCloser);
            messageTimer = setTimeout(function() {
                messageCloser();
            }, 4000);
        }

        form.find('.jButtonSubmit').on('click', function(e) {
            e.preventDefault();
            if(form[0].checkValidity()) {
                form.trigger('submit');
            }
            return false;
        });
        
        link.on('click', function(e) {
            e.preventDefault();
            var isDetailed = link.hasClass('jButtonQuestionDetailed'),
                isOpened = !formHtml.hasClass('hidden');

            if (isOpened === true) {
                $('body').off('keyup', cancel);
                overlay.detach();
                formHtml.addClass('hidden');
            } else {
                if (isDetailed === true) {
                    $('.jDetailedQuestions').show();
                    form.find('input[name=type]').val('detailed');
                } else {
                    $('.jDetailedQuestions').hide();
                    form.find('input[name=type]').val('simple');
                }
                
                form.after(overlay);
                formHtml.removeClass('hidden');
                $('body').on('keyup', cancel).stop(true).scrollTo(0, 500);
            }

            return false;
        });

        return this;
    };
        
})(window.jQuery);
