// $.fn.autoresizetext - autoresize ala xcode label autoresize

(function($) {

    /*
     *  Auto Resize Text - it allows to change text size based on it's max element's width
     */
    $.fn.autoResizeText = function(options) {
        if (!this.length) {
            return this;
        }
        
        var settings = $.extend({}, { minFontSize: 1, maxHeight: Infinity, maxWidth: Infinity }, options),
            labels = $(this);
            
        // Hide to avoid baaad positioning at start.
        labels.css('opacity', 0);

        function processAutoresize() {

            if (document.readyState && document.readyState != 'complete') {

                setTimeout(processAutoresize, 50);
                return;
            }

            labels.each(function() {
                var text = $(this);

                for (var fs = parseInt(text.css('font-size')); fs >= settings.minFontSize; fs--) {
                    var height = text.height(),
                        width = text.width();

                    if (height <= settings.maxHeight && width <= settings.maxWidth) {
                        break;
                    }

                    text.css('font-size', fs);
                }

                text.animate({ 'opacity': 1 }, 250);
            });
        }
        
        setTimeout(processAutoresize, 50);
        
        return this;
    };

})(window.jQuery)
