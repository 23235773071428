// $.fn.selectbox - simulated select box instead of default select box

(function($) {

    /*
     *  OptionsViewer for the selectbox.
     */
    function OptionsViewer(box, settings) {
        this.box = box;
        this.settings = settings;
        this.list = $('<ul />');
        this.listWrapper = $('<div />').addClass('list').append(this.list);
        this.html = $('<div />').addClass('options');
        this.select = box.find('select').hide().remove();
        this.maxVisibleItems = 10;
        
        this.createOptions();
    }
    
    OptionsViewer.prototype.createOptions = function() {
        var self = this,
            options = this.select.find('option'),
            selected = this.select.find('option:selected'),
            count = 0,
            height = undefined,
            items = {},
            scrollHeight = undefined;

        // Create html for the result window.            
        this.html.append('<div class="top-edge"></div>').append(this.listWrapper).append('<div class="bottom-edge"></div>');
        
        // Create dom elements for each item.
        options.each(function() {
            var option = $(this),
                key = option.attr('value'),
                a = $('<a />').attr('href', '#').text(option.text()),
                li = $('<li />').append(a).attr('data-value', key);

            a.on('click', function() {
                self.changeItem(li);
                return false;
            });
            
            if (key) {
                items[key] = li;
                self.list.append(li);                
            }
        })
        
        // Set correct list item as a first item (ignore empty one, usually first one).
        if (selected.length > 0) {
            var key = selected.attr('value'),
                li = items[key];

            if (li) {
                li.addClass('active');
            }
            
            this.box.html(selected.text()).append(this.select);
        }
                
        // Change list height, based on active items. (1 item = 31px, max = 10, more than max adds 8px)
        count = this.list.find('li').length;
        if (count <= this.maxVisibleItems) {
            this.html.height(count * 31);
        } else {
            this.html.height(this.maxVisibleItems * 31 + 8);
        }

        // Rebind mousewheel on each refresh.
        this.listWrapper.unbind('mousewheel').on('mousewheel', function(e, d) {
            var delta = e.originalEvent.wheelDelta ? e.originalEvent.wheelDelta : e.originalEvent.detail;

            if (height === undefined) {
                height = self.listWrapper.height();
            }

            if (scrollHeight === undefined) {
                scrollHeight = self.listWrapper.get(0).scrollHeight;
            }

            if ((this.scrollTop === (scrollHeight - height) && delta < 0) || (this.scrollTop === 0 && delta > 0)) {
                e.preventDefault();
            }
        });
    };
    
    OptionsViewer.prototype.adjustMetrics = function() {
        if (!this.html.parent().length) {
            return;
        }

        var offset = this.box.offset(),
            activeListItem = this.list.find('li.active');

        // Set correct options container position.
        this.html.css({
            width: 202,
            // height: 40, // 195 atm
            top: offset.top + 30,
            left: offset.left
        });
        
        // Scroll list on the right offset for the visible item.
        if (activeListItem.length > 0) {
            var pos = activeListItem.position(),
                mid = this.html.height() / 2 - 15;
            
            if (mid < pos.top) {
                this.listWrapper.scrollTop(pos.top - mid);
            }
        }
    };    

    OptionsViewer.prototype.show = function() {
        this.box.addClass('active');

        if (!this.html.parent().length) {
            $('body').append(this.html.show());
        }
    };

    OptionsViewer.prototype.changeItem = function(item) {
        if (item.hasClass('active') === false) {
            // Handle active class on the list items.
            this.list.find('li').removeClass('active');
            item.addClass('active');
            
            // Update box text of selected item and set it selected in select.
            var val = item.data('value');
            this.box.html(item.text()).append(this.select);
            this.select.find('option').removeAttr('selected');
            this.select.find('option[value=' + val + ']').attr('selected', 'selected');
            
            // Trigger change function if it is specified, for customized code.
            if (this.settings.change !== undefined) {
                this.settings.change.apply(this.box, [item]);
            }
        }
        $('body').trigger('click');
    };

    OptionsViewer.prototype.hide = function(delayed) {
        this.box.removeClass('active');
        
        if (this.html.parent().length) {
            if (delayed === true) {
                this.html.fadeOut('fast', function() {
                    $(this).detach();
                });
            } else {
                this.html.detach();
            }
        }
    };

    /*
     *  Select box.
     */
    $.fn.selectbox = function(options) {
        if (!this.length) {
            return this;
        }

        var context = this,
            settings = $.extend({}, { }, options),

            // Result viewer and result set array.
            optionsViewer = new OptionsViewer(this, settings),

            // Result viewer removal function.
            optionsViewerRemover = function() {
                optionsViewer.hide();
                $(this).unbind('click', optionsViewerRemover);
            };
            
            // Iterate thru result sets.
            // TODO: key iteration
            // iterate = function(direction) {
            //     var selectedIndex = -1;
            // 
            //     for (var i = 0, len = optionsViewer.items.length; i < len; i++) {
            //         if (optionsViewer.items[i].selected === true) {
            //             optionsViewer.items[i].selected = false;
            //             optionsViewer.items[i].anchor.removeClass('selected');
            //             selectedIndex = i;
            //             break;
            //         }
            //     }
            // 
            //     if ((selectedIndex == -1 || selectedIndex == optionsViewer.items.length-1) && direction == 1) {
            //         selectedIndex = 0;
            //     } else if (selectedIndex <= 0 && direction == -1) {
            //         selectedIndex = optionsViewer.items.length - 1;
            //     } else {
            //         selectedIndex += direction;
            //     }
            // 
            //     // Make following list item selected.
            //     optionsViewer.items[selectedIndex].selected = true;
            //     optionsViewer.items[selectedIndex].anchor.addClass('selected');
            // 
            //     // Pass title to the input field.
            //     context.val(optionsViewer.items[selectedIndex].title);
            // };

        // Bind select and it's actions.
        context.on('click', function() {
            if ($(this).hasClass('active') === true) {
                $('body').trigger('click');
                return false;
            }

            optionsViewer.show();
            optionsViewer.adjustMetrics();

            setTimeout(function() {
                $('body').bind('click', optionsViewerRemover);
            }, 20);
        });

        return this;
    };
        
})(window.jQuery);
